import Search from '@/components/search/Search'
import TermSearch from '@/components/search/terms/TermSearch'
import LikeSearch from '@/components/search/terms/LikeSearch'
import DateSearch from '@/components/search/terms/DateSearch'
import ArraySearch from '@/components/search/terms/ArraySearch'
import StringSearch from '@/components/search/terms/StringSearch'

class SalesSearch extends Search {
    sorts = {
        'date': 'date',
        'count': 'document_count',
        'sum': 'document_sum'
    }

    filters = {
        orderDate : new DateSearch('orderDate', 'Период покупок'),
        categories : new ArraySearch('categories', 'Категории', 'id'),
        brands : new ArraySearch('brands', 'Бренды', 'id'),
        shops : new ArraySearch('shops', 'Магазины'),
        sku : new LikeSearch('sku', 'СКУ'),
        title : new LikeSearch('title', 'Артикул'),
        returns : new TermSearch('returns', 'Кол-во возвратов'),
        genders : new ArraySearch('genders', 'Пол', 'id'),
        years : new ArraySearch('years', 'Год'),
        sizes : new ArraySearch('sizes', 'Размеры', 'id'),
        types : new ArraySearch('types', 'Тип/Наименование/Назначение', 'id'),
        discounts : new TermSearch('discounts', 'Кол-во скидочных позиций'),
        email : new StringSearch('email', 'E-Mail'),
        phone : new StringSearch('phone', 'Телефон'),
        fullName : new StringSearch('fullName', 'Ф.И.О.'),
        cardNumber : new LikeSearch('cardNumber', 'Номер карты'),
        documentCode: new StringSearch('documentCode', 'Номер документа'),
        oneOrderSum: new TermSearch('oneOrderSum', 'Сумма одного чека'),
        oneOrderCount: new TermSearch('oneOrderCount', 'Кол-во товаров в чеке'),
        orderReturns: new TermSearch('orderReturns', 'Кол-во возвратов'),
        orderDiscounts: new TermSearch('orderDiscounts', 'Кол-во акционных'),
        singleItemSum: new TermSearch('singleItemSum', 'Сумма одной позиции в чеке')
    }
}

export default SalesSearch
