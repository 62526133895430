<template>
  <div>
    <div class="row">
      <div class="col">
        <CWidgetIcon
            :header="String(total)"
            text="Документы"
            color="gradient-info"
            :icon-padding="false"
        >
          <CIcon name="cil-cart" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
      <div class="col">
        <CWidgetIcon
            :header="stats.sku"
            text="Товары"
            color="gradient-success"
            :icon-padding="false"
        >
          <CIcon name="cil-gift" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
      <div class="col">
        <CWidgetIcon
            :header="stats.customers"
            text="Покупатели"
            color="gradient-danger"
            :icon-padding="false"
        >
          <CIcon name="cil-group" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
      <div class="col">
        <CWidgetIcon
            :header="stats.sum"
            text="Сумма"
            color="gradient-warning"
            :icon-padding="false"
        >
          <CIcon name="cil-money" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </div>
    </div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol class="col-12">
                  <CIcon name="cil-cart"/> {{caption}} <span class="text-muted">({{ total || '0' }})</span>
                </CCol>
                <CCol class="col-12">
                  <search-filter :search="search" :perform-search="performSearch"></search-filter>
                </CCol>
                <CCol class="col-12">
                  <CRow>
                    <CCol class="col-4">
                      <CButton @click="performSearch" :disabled="loading" color="success">
                        <span v-show="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Искать
                      </CButton>
                      <CButton @click="clearSearch" :disabled="loading" class="mx-2" color="danger">Очистить</CButton>

                      <CButton @click="performDownload" class="float-right" color="primary">Скачать</CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDropdown
                color="primary"
                toggler-text="Колонки"
                class="my-2 float-right"
            >
              <div class="dropdown-item" :key="field.key" v-for="field in fields">
                <input :id="field.key" :value="field.key" name="checkedFields" type="checkbox" v-model="checkedFields" />
                <label :for="field.key"><span>{{field.label}}</span></label>
              </div>
            </CDropdown>
            <CDataTable
                :hover=true
                :striped=true
                :border=true
                :items="purchases"
                :fields="allowedFields()"
                :pagination=false
            >
              <template #date-header>
                <div style="cursor: pointer" @click="toggleSort('date')">Дата <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('date')"></CIcon>
                </div>
              </template>
              <template #count-header>
                <div style="cursor: pointer" @click="toggleSort('count')">Кол-во позиций <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('count')"></CIcon>
                </div>
              </template>
              <template #sum-header>
                <div style="cursor: pointer" @click="toggleSort('sum')">Сумма <CIcon
                    class="icon-transition float-right"
                    :name="sortIcon('sum')"></CIcon>
                </div>
              </template>
              <template #count="{item}">
                <td>
                  {{item.sales.length}}
                </td>
              </template>
              <template #show_sales="{item}">
                <td class="py-2">
                  <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item)"
                  >
                    <CIcon :name="Boolean(item._toggled) ? 'cil-caret-top' : 'cil-caret-bottom'"/>
                  </CButton>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                  <CCard>
                    <CCardBody>
                      <CDataTable :items="item.sales" :fields="salesFields" hover></CDataTable>
                    </CCardBody>
                  </CCard>
                </CCollapse>
              </template>
            </CDataTable>
          </CCardBody>
          <CCardFooter>
            <CPagination
                v-if="total !== null"
                class="float-right"
                :activePage.sync="page"
                :pages="pages"
                align="center"
                v-on:update:activePage="loadData"
            />
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import SearchFilter from '@/views/sales/search/SearchFilter'
import sale from '../../api/sale'
import exportApi from '@/api/export'
import SalesSearch from '@/views/sales/search/SalesSearch'

export default {
  name: 'List',
  components: { SearchFilter },
  data() {
    return {
      checkedFields: ['documentCode', 'date', 'shop', 'fullName', 'email', 'phone', 'sum', 'count', 'show_sales'],
      fields:  [
        {
          key: 'documentCode',
          label: 'Код документа'
        },
        {
          key: 'date',
          label: 'Дата'
        },
        {
          key: 'shop',
          label: 'Магазин'
        },
        {
          key: 'fullName',
          label: 'Ф.И.О.'
        },
        {
          key: 'email',
          label: 'E-mail'
        },
        {
          key: 'phone',
          label: 'Телефон'
        },
        {
          key: 'sum',
          label: 'Сумма'
        },
        {
          key: 'count',
          label: 'Кол-во позиций'
        },
        {
          key: 'show_sales',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ],
      salesFields: [
        {
          key: 'sku',
          label: 'СКУ'
        },
        {
          key: 'sum',
          label: 'Сумма'
        },
        {
          key: 'title',
          label: 'Название'
        },
        {
          key: 'count',
          label: 'Кол-во'
        }
      ],
      collapseDuration: 0,
      loading: false,
      search: new SalesSearch(),
      caption: 'Продажи',
      page: 1,
      total: null,
      purchases: [],
      error: null,
      stats: {
        sku: '--',
        customers: '--',
        sum: '--'
      }
    }
  },
  methods: {
    allowedFields() {
      return this.fields.filter(field => this.checkedFields.includes(field.key))
    },
    sortIcon (name) {
      if (this.search.sort.name === name) {
        if (this.search.sort.direction === 'asc') {
          return 'cil-arrow-bottom'
        } else {
          return 'cil-arrow-top'
        }
      } else {
        return 'cil-swap-vertical'
      }
    },
    toggleSort (name) {
      if (this.search.sort.name === name) {
        if (this.search.sort.direction === 'asc') {
          this.search.sort.direction = 'desc'
        } else {
          this.search.sort.name = null
          this.search.sort.direction = null
        }
      } else {
        this.search.sort.name = name
        this.search.sort.direction = 'asc'
      }

      this.performSearch()
    },
    performDownload() {
      exportApi.perform('sales', {
        query: this.search.getQuery(),
        description: this.search.getDescription()
      })
    },
    clearSearch () {
      this.search.clear()
      this.performSearch()
    },
    performSearch () {
      sale.searchTotal(this.search.getQuery()).then(r => this.total = r.data.total)

      this.page = 1
      this.loadData()
      this.loadStats()
    },
    toggleDetails (item) {
      this.$set(this.purchases.find(p => p.documentCode === item.documentCode), '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    loadData () {
      const onSuccess = r => {
        this.purchases = this.mapPurchases(r.data)
        this.loading = false
      }
      const onError = e => {
        this.error = e
        this.loading = false
      }

      this.loading = true
      sale.search(this.search.getQuery(this.page)).then(onSuccess).catch(onError)
    },
    mapPurchases (data) {
      return data.map(s => {
        return {
          date: s.date,
          documentCode: s.documentCode,
          shop: s.shop,
          fullName: s.customer ? s.customer.fullName : '--',
          email: s.customer ? s.customer.email : '--',
          phone: s.customer ? s.customer.phone : '--',
          sum: s.items.reduce((previousValue, currentValue) => previousValue + currentValue.sum, 0),
          sales: s.items
        }
      })
    },
    loadTotal () {
      sale.searchTotal(this.search.getQuery()).then(r => this.total = r.data.total)
    },
    loadStats () {
      sale.searchStats(this.search.getQuery()).then(r => {
        this.stats.sku = String(r.data.sku)
        this.stats.customers = String(r.data.customers)
        this.stats.sum = String(new Intl.NumberFormat('ru-RU').format(r.data.sum))
      })
    },
  },
  computed: {
    pages () {
      return Math.floor(this.total/30)
    }
  },
  created() {
    this.$store.commit('common/set', ['breadcrumbs', [
      {
        text: 'Главная',
        to: '/dashboard'
      },
      {
        text: 'Продажи'
      }
    ]])
    this.loadTotal()
    this.loadData()
    this.loadStats()
  }
}
</script>

<style>
.dropdown-item label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
</style>